.cm__item {
  background: white;
  border: 1px solid #cecece;
  border-radius: 5px;
  margin: 10px 0;
  cursor: pointer;
  padding: 20px;
  transition: all 0.3s;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.cm__body .cm__item {
  position: relative;
  animation: floatup linear 0.25s forwards;
}

// .cm__widget--active {
//   // animation: floatup linear 0.25s forwards;
//   max-height: 10em;
// }

@keyframes floatup {
    0% { 
      top: 2em;
      opacity: 0;
    }
    100% {
      top: 0em;
      opacity: 1;
    }
}

.stripes__block {
    min-width: 15em;
    height: 2em;
    border-radius: 5px;
    flex-grow: 1;
}

.stripes--down {
  --from: -50%;
  --to: 0%;
  background: repeating-linear-gradient(
    45deg,
    #ccc5,
    #ccc5 10px,
    transparent 10px,
    transparent 20px,
  );
  // animation: fade-left 1s ease-out;
}

.stripes--down:nth-of-type(3) {
  --from: 50%;
  --to: 0%;
}

.stripes--up {
  --from: 50%;
  --to: 0%;
  background: repeating-linear-gradient(
    135deg,
    #ccc5,
    #ccc5 10px,
    transparent 10px,
    transparent 20px,
  );
  animation: fade-left 1s ease-out;
}

@keyframes fade-left {
  from {
    transform: translateX(var(--from));
    opacity: 0;
  }


  to {
    transform: translateX(var(--to));
    opacity: 1;
  }
}

.fsc__card-item {
  border: 1px solid #cecece;
  padding: 0.5 1em;
  display: flex;
  align-items: center;
  margin: 1em 0;
}

.fsc__grid {
  padding: 1em;
}
