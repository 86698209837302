@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+Mono:wght@100;300;400;500;700&family=Roboto:wght@100;300;400;500;700;800&display=swap');

html {
/* --paper-white: #e7e7e9; */
--paper-white: white;
--background: #555;
--padding: 3em;
--red: #db2c55;
--theme: var(--red);
--black: #353537;
--text-soft: #58585a;
/* --ratio: 1.29;
--width: 50vw; */
--ratio: 0.7727;
--height: 100vh;
--width: calc(var(--height) * var(--ratio));
/* --main-font: 42px; */
--main-font: 32px;
font-family: 'Roboto';
-webkit-font-smoothing: antialiased;

}

@media screen and (max-height: 600px) {
    html {
        --main-font: 35px;
    }

}
h1, h2, h3, h4, h5 {
margin: 0;
padding: 0;
}

h2 {
font-size: var(--main-font);
font-weight: 500;
letter-spacing: 15px;
}

h5 {
font-size: calc(var(--main-font) * 0.42);
}

.body1 {
    font-size: calc(var(--main-font) * 0.38);
    font-weight: 400;
    color: var(--text-soft);
}

.body2 {
    font-size: calc(var(--main-font) * 0.404);
    font-weight: 400;
    letter-spacing: 0.5px;
}

.text--shaded {
    color: #9d9ea0;
}

.body3 {
    font-size: calc(var(--main-font) * 0.33);
    font-weight: 400;
    color: var(--text-soft);
    letter-spacing: 0.5px;
}
.text--light.text--light {
font-weight: 300;
}

.text--normal.text--normal {
font-weight: 500;
}


.text--bold.text--bold {
font-weight: 800;
}


span.divider-span {
margin: 0 0.25em;
}
.title {
--line-width: 3em;
position: relative;
margin-left: var(--line-width);
padding-left: 25px;
}
.title::before {
content: '';
width: var(--line-width);
height: 2px;
background: var(--theme);
position: absolute;
bottom: 10px;
transform: translateX(-120%);
}


.flex--col {
display: flex;
flex-direction: column;
}

.flex--row {
display: flex;
flex-direction: row;
}

table {
width: 100%;
border-collapse: collapse;
table-layout: auto;
}


th {
text-transform: uppercase;
font-weight: 800;
padding: 1em 10px;
border-top: 1px solid var(--black);
border-bottom: 1px solid var(--black);
border-right: none;
border-left: none;
}

td {
border-bottom: 1px solid #5555;
padding: 2em 10px;
}

tr {
text-align: left;
padding: 10px;
}

body {
    margin: 0;
/* background: var(--background); */
/* margin: 0; */
}

.invoice {
width: var(--width);
height: calc(var(--width) * var(--ratio));
background: white;
height: var(--height);
width: calc(var(--height) * var(--ratio));
background: var(--paper-white);
/* margin: auto; */
/* box-shadow: 0 3px 6px #000A; */
position: relative;
padding: var(--padding);
color: var(--black);
letter-spacing: 2px;
display: flex;
flex-direction: column;
justify-content: space-between;
box-sizing: border-box;
}
@media print {
    .invoice {
        background: white;
        --height: 100vh;
        height: var(--height);
        width: calc(var(--height) * var(--ratio));
    }
}

.ribbon {
--width: 12em;
position: absolute;
top: 0;
right: var(--padding);
width: var(--width);
height: calc(var(--width));
background: var(--theme);
}

.ribbon__img {
height: 100%;
width: 100%;
display: flex;
align-items: center;
justify-content: center;
}

.ribbon__img svg {
    fill: white;
    stroke: white;
    width: 75%;
    height: 75%;
}

.row {
display: flex;
align-items: center;
justify-content: space-between;
width: 100%;
margin: 3em 0;

}

.line {
background: var(--theme);
width: 5em;
height: 2px;
}


